import React from "react";
import {
  AnimatePresence,
  motion,
  useMotionValue,
  useTransform,
} from "framer-motion";
import styled from "styled-components";

import Box from "./motion/motion-box/index";
import ImageBox from "./motion/motion-box/image-box";
import VideoBox from "./motion/motion-box/video-box";
import MotionHeadline from "./motion/motion-headline";
import { Button } from "./globals/button";
import SvgLoader from "./svg/index";
import { useOutsideAlerter } from "../functions/util";

const Checkers = styled.span`
  position: absolute;

  /* LEFT */
  &.checkers-left {
    top: calc(var(--pad) * -1);
    left: 0;
    height: calc(100% + 8px);
    width: var(--pad);
    background: var(--v);
    z-index: 4;
    z-index: 103;
  }

  /* RIGHT */
  &.checkers-right {
    top: calc(var(--pad) * -1);
    right: calc(var(--pad) * -1);
    height: 100%;
    width: var(--pad);
    background: var(--v);
    z-index: 102;
  }

  /* TOP */
  &.checkers-top {
    top: calc(var(--pad) * -1);
    left: 0;
    width: calc(100% + var(--pad));
    height: var(--pad);
    background: var(--h);
    z-index: 101;
  }

  /* BOTTOM */
  &.checkers-bottom {
    bottom: 0;
    left: var(--pad);
    width: 100%;
    height: var(--pad);
    background: var(--h);
    z-index: 102;
  }
`;

export const EntryItem = styled(motion.figure)`
  position: relative;
  padding: 0 0 0 0;
  z-index: 3;
  background: ${({ color }) => color || `var(--light)`};

  &:hover {
    z-index: 9999;
    ${Checkers} {
      &.checkers-bottom,
      &.checkers-top {
        animation-duration: 2s;
        animation-name: animate-h;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      &.checkers-left,
      &.checkers-right {
        animation-duration: 2s;
        animation-name: animate-v;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }

      &.checkers-left {
        /* z-index: 99; */
        animation-direction: reverse !important;
      }
      &.checkers-right {
        /* z-index: 12; */
        top: 0;
      }
      &.checkers-top {
        /* z-index: 7; */
      }
      &.checkers-bottom {
        /* z-index: 11; */
        animation-direction: reverse !important;
        left: 0;
      }
    }
  }

  &[data-pointer="true"] {
    cursor: pointer;
  }

  &:focus-visible:after,
  &:focus-visible:before {
    border: none;
  }

  &:focus-visible {
    .figure-content {
      /* border-width: 10px; */
    }
  }

  @media (hover: hover) {
    &:hover {
      .zoom-button {
        opacity: 1;
        transform: scale(1);
      }
    }
  }
`;

export const EntryContent = styled(Box)`
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  height: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  justify-content: center;
  grid-template-rows: min-content min-content min-content;
  grid-gap: 1.5rem;
  overflow: hidden;
  text-align: center;
  background: ${({ color }) => color || `var(--light)`};
  padding: 4vw 2vw;
  overflow-y: scroll;

  .h5 {
    position: relative;
    width: calc(100% - 1px);
    justify-self: center;
  }

  p {
    max-width: 23em;
  }
`;

export const FigureContent = styled(motion.div)`
  overflow: hidden;
`;

const Shim = styled.div`
  position: absolute;
  z-index: 10;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  pointer-events: none;
  top: -1px;
  left: 0;
`;

const ButtonWrap = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
`;

const ZoomButtonWrap = styled.div`
  position: absolute;
  top: 1rem;
  left: calc(1rem + 8px);
  z-index: 50;
  opacity: 0;
  transform: scale(0.9);
  transition: transform 0.2s
      ${({ theme }) => theme.animation.timingFunction.css},
    opacity 0.2s ${({ theme }) => theme.animation.timingFunction.css};

  svg {
    width: 22px;
    height: 22px;
  }

  &:focus-visible,
  &:focus,
  &:focus-visible:hover {
    opacity: 1 !important;
    transform: scale(1) !important;
  }
`;

const ImageOverflowWrap = styled(motion.div)`
  display: block;
`;

export default function WorkItem({ itemData, id, hideContent, uid }) {
  const ref = React.useRef();
  const [showDetails, setShowDetails] = React.useState(false);
  const [showZoom, setShowZoom] = React.useState(false);
  useOutsideAlerter(ref, setShowDetails, false, uid);
  useOutsideAlerter(ref, setShowZoom, false, uid);

  const imageVariants = {
    initial: {
      scale: 1,
    },
    active: {
      scale: 2,
    },
  };

  const cursorY = useMotionValue(0);
  const cursorX = useMotionValue(0);

  React.useEffect(() => {
    const thisRefContainer = ref?.current;

    const moveCursor = (e) => {
      const cursorYCalc = e.layerY / thisRefContainer.clientHeight - 0.5;
      const cursorXCalc = e.layerX / thisRefContainer.clientWidth - 0.5;

      cursorY.set(cursorYCalc);
      cursorX.set(cursorXCalc);
    };
    if (thisRefContainer) {
      thisRefContainer.addEventListener("mousemove", moveCursor);
      return () => {
        thisRefContainer.removeEventListener("mousemove", moveCursor);
      };
    }
  }, [cursorX, cursorY, ref]);

  React.useEffect(() => {
    if (!showZoom) {
      cursorY.set(0);
      cursorX.set(0);
    }
  }, [cursorX, cursorY, showZoom]);

  const cx = useTransform(cursorX, [-0.5, 0.5], ["50%", "-50%"]);
  const cy = useTransform(cursorY, [-0.5, 0.5], ["50%", "-50%"]);

  return (
    <EntryItem
      ref={ref}
      layout
      layoutId={id}
      className="entry-item"
      data-uid={uid}
      color={itemData.color}
      data-pointer={hideContent ? false : true}
      tabIndex={0}
    >
      <FigureContent
        className="figure-content"
        color={itemData.color}
        onClick={() => {
          if (!showZoom) {
            !hideContent && setShowDetails(!showDetails);
          } else {
            setShowZoom(!showZoom);
          }
        }}
      >
        <ImageOverflowWrap
          variants={imageVariants}
          initial="initial"
          animate={showZoom ? "active" : "initial"}
          style={{ x: showZoom ? cx : 0, y: showZoom ? cy : 0 }}
        >
          {itemData.body &&
            itemData.body.map((bodyItem, i) => {
              if (bodyItem.slice_type === "image") {
                return (
                  <ImageBox
                    key={i}
                    hiddenStyles={{
                      y: 0,
                    }}
                    fluid={bodyItem.primary.image.fluid}
                  />
                );
              } else if (bodyItem.slice_type === "video") {
                return <VideoBox key={i} video={bodyItem.primary.video} />;
              } else return null;
            })}
        </ImageOverflowWrap>
        {!hideContent && (
          <AnimatePresence>
            {showDetails && (
              <>
                <EntryContent
                  color={itemData.color}
                  hiddenStyles={{
                    y: 0,
                    scale: 0.9,
                    transition: { duration: 0.25 },
                  }}
                  transitionOverrides={{ duration: 0.25 }}
                >
                  {itemData.client && (
                    <MotionHeadline text={itemData.client} className="h5" />
                  )}
                  {itemData.title.text && (
                    <MotionHeadline text={itemData.title.text} className="h3" />
                  )}
                  {itemData.body.map(
                    (
                      { slice_type, primary: { link, external_link_text } },
                      i
                    ) => {
                      if (slice_type === "button") {
                        if (!link.url && !external_link_text) return null;
                        return (
                          <Button
                            key={i}
                            href={link.url}
                            data-large={true}
                            blank={link?.target === "_blank"}
                            color={itemData.color}
                          >
                            {external_link_text || "Go"}
                          </Button>
                        );
                      } else return null;
                    }
                  )}
                  {itemData.description.text && (
                    <p>{itemData.description.text}</p>
                  )}
                  <ButtonWrap>
                    <Button
                      onClick={() =>
                        !hideContent && setShowDetails(!showDetails)
                      }
                    >
                      <SvgLoader color="light" svg="X" />
                    </Button>
                  </ButtonWrap>
                </EntryContent>
              </>
            )}
          </AnimatePresence>
        )}
      </FigureContent>

      {!hideContent && (
        <ZoomButtonWrap className="zoom-button">
          <Button
            onClick={() => {
              setShowZoom(!showZoom);
              setShowDetails(false);
            }}
          >
            <SvgLoader color="light" svg={showZoom ? "ZoomOut" : "Zoom"} />
          </Button>
        </ZoomButtonWrap>
      )}
      <Shim />
      <Checkers className="checkers-top" />
      <Checkers className="checkers-right" />
      <Checkers className="checkers-bottom" />
      <Checkers className="checkers-left" />
    </EntryItem>
  );
}
