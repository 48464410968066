import * as React from "react";

function SvgComponent({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.49 2.772a6.719 6.719 0 100 13.437 6.719 6.719 0 000-13.437zM.773 9.491a8.719 8.719 0 1115.55 5.417l5.84 5.84-1.415 1.413-5.839-5.839A8.719 8.719 0 01.772 9.49zm7.719-3.86v2.86H5.63v2h2.86v2.859h2v-2.86h2.859v-2h-2.86V5.632h-2z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgComponent;
