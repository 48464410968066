import React, { useEffect } from "react";
import styled from "styled-components";
import { m, LazyMotion, domAnimation, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";

import { wrapHeadlineInArray } from "../../../functions/util";

export default function MotionHeadline({
  text,
  staggerChildren = 0.025,
  triggerOnce = true,
  ...rest
}) {
  const controls = useAnimation();
  const { ref, inView } = useInView({ triggerOnce: triggerOnce });

  useEffect(() => {
    if (inView) {
      controls.start("active");
    }
    if (!inView) {
      controls.start("pre");
    }
  }, [controls, inView]);

  const textContainerVariants = {
    pre: {},
    active: {
      transition: {
        staggerChildren: staggerChildren,
        duration: 1,
      },
    },
  };

  const textVariants = {
    pre: {
      opacity: 0,
      scale: 1.05,
    },
    active: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.15,
      },
    },
  };

  const array = text !== null ? wrapHeadlineInArray(text) : [];

  return (
    <LazyMotion features={domAnimation}>
      <P
        ref={ref}
        variants={textContainerVariants}
        initial="pre"
        animate={controls}
        {...rest}
      >
        {typeof array === "object"
          ? array.map((item, i) => {
              if (item.text.includes(":")) {
                const cleanText = item.text.replace(":", "").replace(":", "");
                return (
                  <Mask key={i}>
                    <m.em variants={textVariants} data-text={item.text}>
                      {cleanText}
                    </m.em>
                  </Mask>
                );
              } else {
                return (
                  <Mask key={i}>
                    <m.span variants={textVariants} data-text={item.text}>
                      {item.text}
                    </m.span>
                  </Mask>
                );
              }
            })
          : array}
      </P>
    </LazyMotion>
  );
}

const P = styled(m.p)`
  margin-left: -0.16em;
  margin-right: -0.16em;
  margin-bottom: -0.1em;
  margin-top: -0.1em;
`;

const Mask = styled.span`
  position: relative;
  display: inline-block;
  padding: 0.1em 0.12em;
  margin-bottom: -0.15em;
  margin-top: -0.15em;
  > span,
  > em {
    display: inline-block;
    position: relative;
  }
`;
