import * as React from "react";

function SvgComponent({ title, titleId, ...props }) {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.719 2a6.719 6.719 0 100 13.437A6.719 6.719 0 008.719 2zM0 8.719a8.719 8.719 0 1115.55 5.417l5.84 5.84-1.415 1.414-5.839-5.84A8.719 8.719 0 010 8.718zm4.86 1h7.718v-2H4.859v2z"
        fill="#000"
      />
    </svg>
  );
}

export default SvgComponent;
